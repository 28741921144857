<template>

    <div class="s-order-item" @click="navigate" :class="canGoDetail ? '.cursor-pointer' : ''">
      <template v-if="fieldSetting">
        <div class="s-order-item-info">
          <div class="s-space-y-2 s-mr-auto" v-if="leftFieldSetting">
            <template v-for="field in leftFieldSetting">
              <p
                :key="field.key"
                class="s-text-gray s-text-xs s-whitespace-nowrap"
              >
                {{ field.value }}{{ object[field.key] }}
              </p>
            </template>
          </div>
          <div class="s-flex s-flex-col s-items-end" v-if="rightFieldSetting">
            <template v-for="field in rightFieldSetting">
              <p
                :key="field.key"
                class="s-text-gray s-text-xs s-whitespace-nowrap"
              >
                {{ field.value }}{{ object[field.key] }}
              </p>
            </template>
          </div>
        </div>
      </template>
    </div>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      required: true,
    },
    fieldSetting: {
      type: Object,
      required: true,
    },
    showImage: Boolean,
  },
  computed: {
    leftFieldSetting() {
      return this.fieldSetting.list
        .filter((item) => item.position === "left")
        .sort((a, b) => a.order - b.order);
    },
    rightFieldSetting() {
      return this.fieldSetting.list
        .filter((item) => item.position === "right")
        .sort((a, b) => a.order - b.order);
    },
    canGoDetail() {
      return this.fieldSetting.detail.enable ?? false;
    },
  },
  methods: {
    navigate() {
      if (this.canGoDetail) {
        this.$router.push({
          name: "LiffOuterDetail",
          params: {
            id: this.object.id,
            source: this.$route.params.source
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.s-order-item {
  display: flex;
  align-items: center;

  background-color: var(--s-white);

  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0px 2px 8px 0px;
  border-radius: 5px;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 0;
  width: 5rem;
  height: 5rem;
}

.s-order-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-order-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;
}
</style>
