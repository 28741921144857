<template>
  <div>
    <div class="page">
      <div
        v-if="getConfig('form_title')"
        class="page__title"
      >
        {{ getConfig("form_title") }}
      </div>
      <div v-if="getConfig('form_desc')" class="page__desc">
        {{ getConfig("form_desc") }}
      </div>
      <div
        v-if="getConfig('content')"
        v-html="getConfig('content')"
      ></div>
      <div
        v-if="getConfig('content_url')"
        v-html="getConfig('content_url')"
      ></div>
      <img
        v-for="image in contentImages"
        :src="image.image_url"
        :key="image.order"
        class="w-100"
      />
      <template v-if="objects.length">
        <ul class="s-p-3">
          <li class="mb-3" v-for="(object, i) in objects" :key="i">
            <Item
              :object="object"
              :field-setting="getConfig('field_setting')"
            />
          </li>
        </ul>
        <Pagination :current="currentPage" :last="lastPage" />
      </template>
      <div class="s-pt-40 s-text-center" v-else>
        <img class="s-mb-3" src="@/assets/images/liff/empty.svg" />
        <p class="s-text-gray">沒有內容</p>
        <p class="s-text-gray">小幫手每 3 分鐘會更新一次狀態唷</p>
      </div>
    </div>
    <div class="page__button s-space-y-4" v-if="getConfig('button_text')">
      <MemberButton
        :button-text="getConfig('button_text')"
        :button-url="getConfig('button_url')"
        :type="getConfig('button_action')"
      >
      </MemberButton>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import { mapGetters } from "vuex";
import liff from "@line/liff";
import Item from "@/components/Page/Liff/Outer/Item.vue";
import Pagination from "@/components/Page/Liff/Outer/Pagination.vue";
import outerApi from "@/apis/liff/v2/outer";
import outerMixin from "@/mixins/liff/outer";

export default {
  mixins: [outerMixin],
  components: {
    MemberButton,
    Item,
    Pagination
  },

  data() {
    return {
      showLoading: false,
      currentPage: 1,
      lastPage: 1,
      objects: []
    };
  },

  created() {},

  async mounted() {
    await this.init();
    // await this.sendLiffMessage();
  },

  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    contentImages() {
      let contentImages = this.getConfig("content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    page() {
      return this.$route.params.source + "_page";
    },
  },
  methods: {
    getConfig(key) {
      return this.getModuleConfig(this.page + '.' + key);
    },
    async sendLiffMessage() {
      if (this.getConfig("send_messages.enable")) {
        await liff
          .sendMessages([
            {
              type: "text",
              text: this.getConfig("send_messages.text"),
            },
          ])
          .then(() => {
            console.log("message sent");
          })
          .catch((err) => {
            console.log("error", err);
          });
        return;
      }
    },
    async fetchObjects() {
      this.showLoading = true;
      const { data, lastPage } = await outerApi.getList({
        source: this.page,
        page: this.currentPage,
      })
      this.objects = data;
      this.lastPage = lastPage;
      this.showLoading = false;
    },
    async init() {
      // await liff
      //   .init({
      //     liffId: this.liffId,
      //   })
      //   .then(() => {
      //     if (!liff.isLoggedIn()) {
      //       liff.login({
      //         redirectUri: window.location.href,
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.liffFailed();
      //   });
      this.fetchObjects()
  },
  },
};
</script>

<style  scoped>

.s-order-item {

  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--s-white);

  box-shadow: rgb(from var(--s-primary) r g b / .5)  0px 2px 8px 0px;
  border-radius: 5px;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 0;
  width: 5rem;
  height: 5rem;
}

.s-order-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-order-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;
}
</style>
